import React from "react";
import Icon from "../components/Icon";
import { useNavigate } from "react-router-dom";

const Module = ({ iconName, title, id, index = '' }) => {
  const navigate = useNavigate();
  const mod_index = `${id}/${index || ''}`
  return (
    <div
      className="modulo mx-3 bg-white shadow rounded-2 p-2 d-flex flex-column align-items-center justify-content-evenly"
      onClick={() => navigate(`/${mod_index}`, { replace: false })}
      style={{
        height: "127px",
        width: "216px",
        cursor: "pointer",
      }}
    >
      <Icon
        iconName={iconName}
        className="m-0 "
        size="2xl"
        // color="#003550"
      ></Icon>

      <p className="m-0 text-center">{title}</p>
    </div>
  );
};

export default Module;
