import Button from "react-bootstrap/Button";
import "bootstrap/dist/css/bootstrap.min.css";
//import Input from "../components/Input";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import { useState } from "react";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import { logger } from "../services/getUser.js";

export default function Login() {
  // ==================== States ====================
  const [showPassword, setShowPassword] = useState(false);
  const [userData, setUserData] = useState({ user: "", pass: "" });
  const [sessionData, setSessionData] = useState({
    statusbar: 200,
    messagge: "",
    hidden: true,
  });

  // ==================== Functions ====================
  const initSession = async (e) => {
    e.preventDefault();
    const session = await logger(userData.user, userData.pass);
    if (session.status) {
      e.target.user.className = "form-control is-invalid";
      e.target.pass.className = "form-control is-invalid";

      setSessionData({
        statusbar: session.status,
        messagge: session.data,
        hidden: false,
      });
    } else {
      window.localStorage.setItem("keyToken", JSON.stringify(session));
      window.localStorage.removeItem("provSelect");
      window.localStorage.removeItem("token_bas");
      window.location = "/";
      window.history.replaceState({}, "");
    }
  };

  const handleChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    const className = e.target.className;

    e.target.className = className.replace("is-invalid", "");

    setSessionData({
      statusbar: 200,
      messagge: "",
      hidden: true,
    });

    setUserData({
      ...userData,
      [name]: value,
    });
  };

  return (
    <>
      <div className="row col-md-12">
        {/* Columna 1 */}
        <div className="col-md-8 p-0">
          <div
            className="imagen-fondo"
            style={{
              backgroundImage: `url(${require("../../../img/background.jpg")})`,
            }}></div>
        </div>
        {/* Columna 2 */}
        <div
          className="col-md-4 p-0 d-flex row align-items-center justify-content-center"
          style={{ backgroundColor: "#003550" }}>
          <div className="d-flex row align-items-center justify-content-center col-12 h-75">
            <div className="d-flex row align-items-center justify-content-center">
              <img
                src={require("../../../img/fadel.png")}
                alt="Logo Empresa"
                className="col-4 img-fluid mb-3 "
              />

              <h2 className="text-white fw-light text-center">
                Sistema Integral Web - Fadel S.A.
              </h2>
            </div>
            <div className="col-7 rounded" style={{ backgroundColor: "white" }}>
              <h3 className="text-black fw-light text-center m-0 py-5">
                Iniciar sesión
              </h3>
              <Form className="pb-5" onSubmit={initSession}>
                <div className="mb-4">
                  <Form.Control
                    id="user"
                    name="user"
                    className="form-control"
                    placeholder="Usuario o CUIT"
                    onChange={(e) => handleChange(e)}
                    value={userData?.user}
                    required
                  />
                </div>

                <InputGroup className="mb-4 col">
                  <Form.Control
                    id="pass"
                    name="pass"
                    className="col-2 col"
                    type={showPassword ? "text" : "password"}
                    placeholder="Contraseña"
                    onChange={(e) => handleChange(e)}
                    value={userData?.pass}
                    required
                  />
                  <Button
                    className="border-start-0"
                    name=""
                    onClick={() => setShowPassword(!showPassword)}
                    style={{
                      background: "white",
                      borderColor: "#dee2e6",
                      color: "#6c757d",
                    }}>
                    {!showPassword ? <FaEyeSlash /> : <FaEye />}
                  </Button>
                </InputGroup>

                <div className="d-grid gap-2 pb-4">
                  <Button
                    style={{
                      border: "none",
                      "--bs-btn-bg": "rgb(48, 99, 45)",
                      "--bs-btn-hover-bg": "#377134",
                      "--bs-btn-active-bg": "rgb(48, 99, 45)",
                    }}
                    type="submit">
                    INICIAR SESIÓN
                  </Button>
                </div>
                <p
                  className="text-danger m-0 pt-3 text-center"
                  hidden={sessionData.hidden}
                  name="messaggeError">
                  {sessionData?.messagge}
                </p>
              </Form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
