import { lazy } from "react";
import "./App.css";
import {
  Route,
  createRoutesFromElements,
  createHashRouter,
} from "react-router-dom";
import { library } from "@fortawesome/fontawesome-svg-core";
import * as Icons from "@fortawesome/free-solid-svg-icons";

// Rutas
// =================[ Rutas globales ]=================
import Layout from "./pages/LayoutLeft";
import Default from "./pages/Default";
import Login from "./pages/login/views/Login";
import Modulos from "./pages/Modulos.jsx";
import { getUserMenu } from "./pages/userAdmin/services/service";

// =================[ Rutas de los módulos. ]=================
// Recupero los datos del usuario
const loggedUserToken = window.localStorage.getItem("keyToken");
const jsonToken = JSON.parse(loggedUserToken);
// Consulto cuales son los módulos e items a los que puede acceder el usuario.
const userMenu = await getUserMenu(jsonToken?.id);
const menu = userMenu?.data?.items || [];
const modulos = userMenu?.data?.modulos || [];

menu.unshift({
  PATH: `${process.env.REACT_APP_ROOT_PATH_FILE}Home`,
  PATHNAME: "/:mod",
});
// Genero un lista de rutas con para la config del usuario.
const arrayRutas = menu.map((ruta) => {
  const ElementMenu = lazy(() =>
    import(`${process.env.REACT_APP_ROOT_PATH_FILE}${ruta.PATH}`)
  );
  const contieneID = ruta.PATHNAME.search(":id");

  if (contieneID !== -1) {
    return (
      <Route
        key={ruta.PATHNAME}
        path={ruta.PATHNAME}
        element={<ElementMenu menu={ruta.MENU} />}
        action={async ({ request }) => {
          let formData = await request.formData();
          return { ticket: formData.get("ticket") };
        }}></Route>
    );
  }

  return (
    <Route
      key={ruta.PATHNAME}
      path={ruta.PATHNAME}
      element={<ElementMenu menu={ruta.MENU} />}></Route>
  );
});
// ===========================================================
// Configuración global para importar íconos de forma dinámica.
const iconList = Object.keys(Icons)
  .filter((key) => key !== "fas" && key !== "prefix")
  .map((icon) => Icons[icon]);
library.add(...iconList);
// ===========================================================

const router = createHashRouter(
  createRoutesFromElements(
    <Route>
      <Route
        path="/:mod"
        element={<Layout menu={menu} mods={modulos} />}
        action={async ({ request }) => {
          let formData = await request.formData();
          return { ticket: formData.get("mod") };
        }}>
        {arrayRutas}
      </Route>
      <Route path="login" element={<Login />}></Route>
      <Route path="/" element={<Modulos modulos={modulos} />}></Route>
      <Route path="*" element={<Default />}></Route>
    </Route>
  )
);

export default router;
