import React, { useEffect, useState } from "react";
import { getDatosUser } from "./login/services/getUser.js";
import { faHouseChimney } from "@fortawesome/free-solid-svg-icons";
import Module from "../components/Module";
import Sidebar from "../components/Sidebar";
import BarraInfo from "../components/BarraInfo.jsx";
import useSession from "../hooks/useSession.js";

function Modulos({ modulos }) {
  const [userData, setUserData] = useState([]);
  const generateIconUser = () => {
    const letter = document.getElementById("user-info-letter");
    const user = userData?.nombre || false;
    if (user && letter) {
      const nombre_apellido = user.split(", ");
      letter.textContent =
        nombre_apellido[1][0].trim() + nombre_apellido[0][0].trim();
    }
  };

  useSession();

  useEffect(() => {
    const loggedUserToken = window.localStorage.getItem("keyToken");
    const jsonToken = JSON.parse(loggedUserToken);
    Promise.all([getDatosUser(jsonToken?.id)]).then((res) => {
      const DatosUser = res[0];
      setUserData(DatosUser);
    });
  }, []);
  generateIconUser();
  return (
    <>
      <div className="layout">
        <Sidebar
          title="Sistema Integral Web - Fadel SA"
          menu={[
            {
              url: "/",
              iconName: faHouseChimney,
              title: "Inicio",
            },
          ]}
        />
        <div className="content width-full-vh">
          {/* ======================================= */}

          {userData.data?.nroPlanta !== undefined ? (
            <BarraInfo data={userData}> </BarraInfo>
          ) : null}

          {/* ======================================= */}
          <div
            className="main-content-modules"
            style={{ paddingBottom: "20%" }}>
            <div style={{ position: "relative" }}>
              <div className="svg-shape">
                <svg
                  className="rounded-top"
                  xmlns="http://www.w3.org/2000/svg"
                  width="100%"
                  height="100%"
                  viewBox="0 0 1540 289"
                  fill="none">
                  <path
                    d="M0 0H1540V232C1540 232 851 288.5 743 288.5C635 288.5 0 232 0 232V0Z"
                    fill="#A2C6D8"
                  />
                </svg>
              </div>
              <div className="contenedor-texto-modulos" style={{ zIndex: 0.9 }}>
                <div className="contenedor-texto px-5">
                  <div className="d-flex col align-items-center mb-3">
                    <h2 className="m-0 text-white">Hola!</h2>
                  </div>
                  <p className="mb-5">
                    Seleccione el módulo al que desea ingresar
                  </p>
                </div>
                <div className="contenedor-modulos d-flex px-5 pb-5">
                  {modulos.map((modulo) => (
                    <Module
                      iconName={modulo.ICONO}
                      title={modulo.DESCRIPCION}
                      id={modulo.ID}
                      index={modulo.MOD_INDEX}
                      key={modulo.ID}></Module>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Modulos;
