import React, { useState } from "react";
import { Link } from "react-router-dom";
import Icon from "./Icon";
import { useNavigate } from "react-router-dom";
import {
  faArrowRightFromBracket,
  faKey,
} from "@fortawesome/free-solid-svg-icons";
import ChangePwd from "../pages/userAdmin/views/ChangePwd";
import Modal from "../components/Modal";

function Sidebar({ menu = [], idMenu = 0, title = "" }) {
  const [changePwdState, setChangePwdState] = useState(false);
  const navigate = useNavigate();
  const loggedUserToken = window.localStorage.getItem("keyToken");
  const jsonToken = JSON.parse(loggedUserToken);

  const handledSelect = (selectedItem) => {
    const activateItem = selectedItem.classList.contains("active-item");
    let oldItem = document.querySelector(".active-item");
    if (!activateItem) {
      if (oldItem) oldItem.classList.remove("active-item");
      selectedItem.closest(".nav-item").classList.add("active-item");
    }
  };

  const handleLogout = () => {
    window.localStorage.removeItem("keyToken");
    navigate("/login", { replace: true });
  };

  return (
    <div className="sidebar" style={{ zIndex: 1 }}>
      <div className="header-sidebar">
        <img src={require("../img/fadel-opaco.png")} alt="Logo Empresa" />
        <span className="sidebar-text">
          {title} <div></div>
        </span>
      </div>
      <hr className="divider" />

      <div>
        {menu.map(function (item, i) {
          let URLactual = window.location.pathname;
          let classActive = "";
          const urlItem = item.url === "/" ? "/" : `/${idMenu}/${item.url}`;
          if (URLactual === urlItem) classActive = "active-item";

          return (
            <div className="items " key={i}>
              <Link
                className="nav-link"
                to={item.url}
                onClick={(e) => handledSelect(e.target)}>
                <div className={`nav-item ${classActive}`}>
                  <Icon iconName={item.iconName} />
                  <span className="sidebar-text">{item.title}</span>
                </div>
              </Link>
            </div>
          );
        })}
      </div>
      <div
        className="mt-auto"
        style={{ display: "flex", flexDirection: "column" }}>
        <button
          onClick={() => {
            setChangePwdState(true);
          }}
          className="btn text-white mx-4 mb-3 d-flex align-items-center justify-content-center"
          style={{
            height: "40px",
            border: "none",
            "--bs-btn-bg": "rgb(48, 99, 45)",
            "--bs-btn-hover-bg": "#377134",
            "--bs-btn-active-bg": "rgb(48, 99, 45)",
          }}>
          <Icon iconName={faKey} />
          <span className="sidebar-text">Cambiar contraseña</span>
        </button>

        <button
          onClick={handleLogout}
          className="btn text-white mx-4 mb-3 d-flex align-items-center justify-content-center"
          style={{
            height: "40px",
            border: "none",
            "--bs-btn-bg": "#0075AE",
            "--bs-btn-hover-bg": "#0086C8",
            "--bs-btn-active-bg": "#0086C8",
          }}>
          <Icon iconName={faArrowRightFromBracket} />
          <span className="sidebar-text">Cerrar sesión</span>
        </button>
      </div>
      {changePwdState && (
        <Modal
          setOpenModal={setChangePwdState}
          title={"Cambiar contraseña"}
          size="md">
          <ChangePwd
            id={jsonToken?.id}
            refresh={() => {
              setTimeout(() => {
                handleLogout();
              }, 3000);
            }}
          />
        </Modal>
      )}
    </div>
  );
}

export default Sidebar;
