const ENV = {
  DEVELOPMENT: "localhost",
};

let API_CORE = "";
const ENV_CORE = window.location.hostname;

if (ENV_CORE === ENV.DEVELOPMENT) {
  API_CORE = "http://localhost:3001/api/v1";
  window.document.title = " DESA | Sistema Integral Web - Fadel S.A.";
} else {
  API_CORE = "https://apiweb.fadel.com.ar:3001/api/v1";
  window.document.title = "Sistema Integral Web - Fadel S.A.";
}

const END_POINTS = {
  // ===========================[ Carta de Porte ]=========================
  login: `${API_CORE}/users/login`,
  userProveedor: `${API_CORE}/users/dataUserProvider`,
  user: `${API_CORE}/cpe/user`,
  signUp: `${API_CORE}/users/signUp`,
  users: `${API_CORE}/users/`,
  restoreUsers: `${API_CORE}/users/restore`,
  changePwd: `${API_CORE}/users/changePwd`,
  searchUser: `${API_CORE}/users/searchUser`,
  searchProv: `${API_CORE}/users/searchProv`,
  proveedores: `${API_CORE}/users/proveedores`,
  usuariosPorProv: `${API_CORE}/users/usuariosPorProv`,
  userMenuConfig: `${API_CORE}/users/userMenuConfig`,
  usersCountPage: `${API_CORE}/cpe/user/users/countpage`,
  isLogged: `${API_CORE}/cpe/user/isLogged`,
  instalaciones: `${API_CORE}/cpe/instalaciones`,
  ticketsAll: `${API_CORE}/cpe/tickets/all`,
  ticketsCountPage: `${API_CORE}/cpe/tickets/countpage`,
  ticket: `${API_CORE}/cpe/tickets`,
  ticketDetail: `${API_CORE}/cpe/tickets/detail`,
  confirmacionCPE: `${API_CORE}/cpe/tickets/confirmacionCPE`,
  ubiProvincias: `${API_CORE}/cpe/ubicaciones/provincias`,
  ubiLocalidades: `${API_CORE}/cpe/ubicaciones/localidades`,
  ubiDomicilio: `${API_CORE}/cpe/ubicaciones/domicilio`,
  // ==============================[ Proveedor ]============================
  agregarAsocProv: `${API_CORE}/users/agregarAsocProveedor`,
  deleteAsocProv: `${API_CORE}/users/deleteAsocProveedor`,
  grupos: `${API_CORE}/users/grupos`,
  tokenProvider: `${API_CORE}/prov/token`,
  dataProvider: `${API_CORE}/prov/data`,
  provSaldo: `${API_CORE}/prov/saldo`,
  facturaCC: `${API_CORE}/prov/ordenDePagoPDF`,
  retencionCC: `${API_CORE}/prov/retencionPDF`,
  detallesPagos: `${API_CORE}/prov/detallesPagos`,
  countPagesCtaCtes: `${API_CORE}/prov/countPagesCtaCtes`,
};

const ROLES = { ADMIN: 1, NORMAL: 2 };

export { END_POINTS, ROLES };
