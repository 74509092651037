import { useEffect } from "react";
import { verifyToken } from "../pages/login/services/getUser";
import { useNavigate } from "react-router-dom";

function useSession() {
  const navigate = useNavigate();
  useEffect(() => {
    Promise.all([verifyToken()]).then((res) => {
      const verifyResult = res[0];
      if (verifyResult.data?.error || verifyResult.error) {
        navigate("/login", { replace: true })
      };
    });
  }, [navigate]);
}
export default useSession;
