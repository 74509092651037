import axios from "axios";
import { END_POINTS } from "../../../utilities/constantes.js";
import { axiosRequest, nvl } from "../../../utilities";

export const getUserDetail = (id, page, limit) => {
  const options = {
    method: "POST",
    url: END_POINTS.userProveedor,
    data: { id, page, limit },
  };

  return new Promise((resolve) => {
    axios
      .request(options)
      .then(function (response) {
        resolve(response.data);
      })
      .catch(function (error) {
        resolve(error.response);
      });
  });
};

export const agregarAssocProveedor = (prov, id) => {
  const options = {
    method: "POST",
    url: END_POINTS.agregarAsocProv,
    data: { prov, id },
  };
  return new Promise((resolve, reject) => {
    axiosRequest(options).then(function (response) {
      resolve(response);
    });
  });
};

export const getSearchUser = (user, eliminado) => {
  const options = {
    method: "GET",
    url: END_POINTS.searchUser,
    params: { user, eliminado },
  };
  return new Promise((resolve, reject) => {
    axiosRequest(options).then(function (response) {
      resolve(response);
    });
  });
};

export const deleteAsocProveedor = (id) => {
  const options = {
    method: "DELETE",
    url: END_POINTS.deleteAsocProv,
    data: { cod: id },
  };
  return new Promise((resolve, reject) => {
    axiosRequest(options).then(function (response) {
      resolve(response);
    });
  });
};

export const getSearchProv = (prov, limit, page) => {
  const options = {
    method: "GET",
    url: END_POINTS.searchProv,
    params: { prov, limit, page },
  };
  return new Promise((resolve, reject) => {
    axiosRequest(options).then(function (response) {
      resolve(response);
    });
  });
};

export const getUserMenu = (user) => {
  const loggedUserToken = window.localStorage.getItem("keyToken");
  const jsonToken = JSON.parse(loggedUserToken);
  const options = {
    method: "GET",
    headers: {
      authorization: `Bearer ${jsonToken?.token}`,
    },
    url: END_POINTS.userMenuConfig,
    params: { user },
  };
  return new Promise((resolve, reject) => {
    axiosRequest(options).then(function (response) {
      resolve(response);
    });
  });
};

export const getGrupos = async () => {
  const options = {
    method: "GET",
    url: END_POINTS.grupos,
  };
  return new Promise((resolve, reject) => {
    axiosRequest(options).then(function (response) {
      resolve(response);
    });
  });
};

export const getProveedoresSinUsuario = (
  ParamBusqueda,
  filtroEstado,
  page,
  limit,
  field
) => {
  const loggedUserToken = window.localStorage.getItem("keyToken");
  const jsonToken = JSON.parse(loggedUserToken);
  const options = {
    method: "POST",
    url: END_POINTS.proveedores,
    headers: {
      authorization: `Bearer ${jsonToken?.token}`,
    },
    data: { ParamBusqueda, filtroEstado, limit, page, field },
  };
  return new Promise((resolve, reject) => {
    axiosRequest(options).then(function (response) {
      resolve(response);
    });
  });
};

export const getUsuariosPorProv = (prov, page, limit) => {
  const loggedUserToken = window.localStorage.getItem("keyToken");
  const jsonToken = JSON.parse(loggedUserToken);
  const options = {
    method: "GET",
    url: END_POINTS.usuariosPorProv,
    headers: {
      authorization: `Bearer ${jsonToken?.token}`,
    },
    params: { prov: nvl(prov, "").trim(), page, limit },
  };
  return new Promise((resolve, reject) => {
    axiosRequest(options).then(function (response) {
      resolve(response);
    });
  });
};
export const changePwd = async (id, { pass, pass2 }) => {
  const loggedUserToken = window.localStorage.getItem("keyToken");
  const jsonToken = JSON.parse(loggedUserToken);
  if (pass.value !== pass2.value)
    return { status: "error", data: { msg: "Las contraseñas no coinciden" } };

  const options = {
    method: "PUT",
    url: `${END_POINTS.changePwd}/${id}`,
    headers: {
      Authorization: `Bearer ${jsonToken?.token}`,
    },
    data: { pwd: pass.value },
  };

  axios
    .request(options)
    .then(function (response) {})
    .catch(function (error) {
      console.error(error);
    });
};
