import useSession from "../hooks/useSession";

function Default() {
  useSession();
  return (
    <>
      {/* <section className="flex flex-column justify-center align-middle "> */}
      <section className="d-flex row align-items-center justify-content-center vh-100">
        <div className="text-center">
          <div className="col">
            <img
              src={require("../img/GALLINA-404.png")}
              alt=""
              className="w-25"
            />
          </div>
          <h1 style={{ color: "#92B9FF" }}>Página no encontrada</h1>
        </div>
      </section>
    </>
  );
}

export default Default;
