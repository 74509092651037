import axios from "axios";
import { END_POINTS } from "../../../utilities/constantes.js";

export const logger = async (user, pass) => {
  const options = {
    method: "POST",
    url: END_POINTS.login, //'http://localhost:3002/api/v1/user/login',
    data: { user, pass },
  };

  return new Promise((resolve, reject) => {
    axios
      .request(options)
      .then(function (response) {
        resolve(response.data);
      })
      .catch(function (error) {
        resolve(error.response);
      });
  });
};
/** Se encar de dar de alta al usuario nuevo en la base de datos. Solo si se tiene usuario con Rol de Administrador*/
export const createUser = async ({
  user,
  username,
  pass,
  cuit,
  rol,
  grupo,
}) => {
  const loggedUserToken = window.localStorage.getItem("keyToken");
  const jsonToken = JSON.parse(loggedUserToken);

  const options = {
    method: "POST",
    url: END_POINTS.signUp,
    headers: {
      authorization: `Bearer ${jsonToken?.token}`,
    },
    data: { user, username, pass, cuit, rol, grupo },
  };

  return new Promise((resolve, reject) => {
    axios
      .request(options)
      .then(function (response) {
        resolve(response.data);
      })
      .catch(function (error) {
        resolve(error.response);
      });
  });
};

export const updateUser = async ({
  id,
  user,
  username,
  pass,
  grupo,
  rol,
  cuit,
  inst,
}) => {
  const loggedUserToken = window.localStorage.getItem("keyToken");
  const jsonToken = JSON.parse(loggedUserToken);

  const options = {
    method: "PUT",
    url: `${END_POINTS.users}/${id}`,
    headers: {
      Authorization: `Bearer ${jsonToken?.token}`,
    },
    data: { id, user, username, pass, grupo, cuit, rol, inst },
  };

  return new Promise((resolve, reject) => {
    axios
      .request(options)
      .then(function (response) {
        resolve(response.data);
      })
      .catch(function (error) {
        resolve(error.response);
      });
  });
};
/** Función para verificar que el usuario tiene un token de inicio de sesión
 * @param {String} token
 * @returns response
 */
export const verifyToken = async (token) => {
  return new Promise((resolve, reject) => {
    const loggedUserToken = window.localStorage.getItem("keyToken");
    const jsonToken = JSON.parse(loggedUserToken);

    const options = {
      method: "POST",
      url: END_POINTS.isLogged,
      headers: {
        Authorization: `Bearer ${jsonToken?.token}`,
      },
    };

    axios
      .request(options)
      .then(function (response) {
        resolve(response.data);
      })
      .catch(function (error) {
        resolve(error.response);
      });
  });
};
export const getDatosUser = async (userId) => {
  return new Promise((resolve, reject) => {
    const loggedUserToken = window.localStorage.getItem("keyToken");
    const jsonToken = JSON.parse(loggedUserToken);

    const options = {
      method: "GET",
      url: `${END_POINTS.users}/${userId}`,
      headers: {
        Authorization: `Bearer ${jsonToken?.token}`,
      },
    };

    axios
      .request(options)
      .then(function (response) {
        resolve(response.data);
      })
      .catch(function (error) {
        console.error(error);
      });
  });
};

export const deleteUser = async (userId) => {
  return new Promise((resolve, reject) => {
    const loggedUserToken = window.localStorage.getItem("keyToken");
    const jsonToken = JSON.parse(loggedUserToken);

    const options = {
      method: "DELETE",
      url: `${END_POINTS.users}/${userId}`,
      headers: {
        Authorization: `Bearer ${jsonToken?.token}`,
      },
    };
    axios
      .request(options)
      .then(function (response) {
        resolve(response.data);
      })
      .catch(function (error) {
        console.error(error);
      });
  });
};
export const getUsers = async (page, limit, filtroEstado, ParamBusqueda) => {
  return new Promise((resolve, reject) => {
    const loggedUserToken = window.localStorage.getItem("keyToken");
    const jsonToken = JSON.parse(loggedUserToken);

    const options = {
      method: "GET",
      url: `${END_POINTS.users}`,
      params: { page, limit, filtroEstado, ParamBusqueda },
      headers: {
        Authorization: `Bearer ${jsonToken?.token}`,
      },
    };

    axios
      .request(options)
      .then(function (response) {
        resolve(response.data);
      })
      .catch(function (error) {
        resolve(error.response);
      });
  });
};
export const getCountPage = async (limit) => {
  return new Promise((resolve, reject) => {
    const loggedUserToken = window.localStorage.getItem("keyToken");
    const jsonToken = JSON.parse(loggedUserToken);

    const options = {
      method: "GET",
      url: `${END_POINTS.usersCountPage}`,
      params: { limit: limit },
      headers: {
        Authorization: `Bearer ${jsonToken?.token}`,
      },
    };
    axios
      .request(options)
      .then(function (response) {
        resolve(response.data);
      })
      .catch(function (error) {
        console.error(error.response.data);
      });
  });
};

export const restoreUser = async (id) => {
  return new Promise((resolve, reject) => {
    const loggedUserToken = window.localStorage.getItem("keyToken");
    const jsonToken = JSON.parse(loggedUserToken);

    const options = {
      method: "PUT",
      url: `${END_POINTS.restoreUsers}/${id}`,
      headers: {
        Authorization: `Bearer ${jsonToken?.token}`,
      },
    };
    axios
      .request(options)
      .then(function (response) {
        resolve(response.data);
      })
      .catch(function (error) {
        console.error(error);
      });
  });
};
