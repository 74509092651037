import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const Icon = ({ iconName, className = "", size = "1x", color = "" }) => {
  return (
    <div className={`icon ${className}`}>
      <FontAwesomeIcon icon={iconName} size={size} color={color} />
    </div>
  );
};

export default Icon;
