import React from "react";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import Button from "react-bootstrap/Button";
import Alert from "react-bootstrap/Alert";
import { useState } from "react";
import { changePwd } from "../../userAdmin/services/service.js";

const ChangePwd = ({ id, refresh }) => {
  const [showPassword, setShowPassword] = useState(false);
  const [showPassword2, setShowPassword2] = useState(false);
  const [msgConfirmacion, setMsgConfirmacion] = useState({
    error: false,
    show: false,
    msg: "",
  });

  const handleSubmit = async (e) => {
    e.preventDefault();

    const { pass, pass2 } = e.target;
    const pwd = { pass, pass2 };
    const resChangePwd = await changePwd(id, pwd);

    if (resChangePwd?.status) {
      setMsgConfirmacion({
        error: true,
        show: true,
        msg: `${resChangePwd.data.msg}`,
      });
      console.error(resChangePwd);
    } else {
      setMsgConfirmacion({
        error: false,
        show: true,
        msg: "Contraseña actualizada correctamente",
      });
      refresh();
    }

    setTimeout(() => {
      setMsgConfirmacion({ error: false, show: false, msg: "" });
      e.target.reset();
    }, 3000);
  };
  return (
    <>
      {msgConfirmacion.show && (
        <div className={"d-flex justify-content-start mb-3"}>
          <Alert
            key={msgConfirmacion.error ? "danger" : "success"}
            variant={msgConfirmacion.error ? "danger" : "success"}
            className="m-0 col-12">
            {msgConfirmacion.msg}
          </Alert>
        </div>
      )}
      <Form
        className="col-12 col"
        onSubmit={handleSubmit}
        action=""
        method="post">
        <Form.Label> Contraseña nueva:</Form.Label>
        <InputGroup className="mb-4">
          <Form.Control
            id="pass"
            name="pass"
            type={showPassword ? "text" : "password"}
            required
          />
          <Button
            className="border-start-0"
            name=""
            onClick={() => setShowPassword(!showPassword)}
            style={{
              background: "white",
              borderColor: "#dee2e6",
              color: "#6c757d",
            }}>
            {!showPassword ? <FaEyeSlash /> : <FaEye />}
          </Button>
        </InputGroup>
        <Form.Label>Repetir contraseña:</Form.Label>
        <InputGroup className="mb-4">
          <Form.Control
            id="pass2"
            name="pass2"
            type={showPassword2 ? "text" : "password"}
            required
          />
          <Button
            className="border-start-0"
            name=""
            onClick={() => setShowPassword2(!showPassword2)}
            style={{
              background: "white",
              borderColor: "#dee2e6",
              color: "#6c757d",
            }}>
            {!showPassword2 ? <FaEyeSlash /> : <FaEye />}
          </Button>
        </InputGroup>
        <div className="d-flex justify-content-end pt-2">
          <Button variant="success" type="submit">
            Guardar
          </Button>
        </div>
      </Form>
    </>
  );
};

export default ChangePwd;
