import Modal from "react-bootstrap/Modal";

// import { useEffect } from "react";
// import Button from "react-bootstrap/Button";
// import { useNavigate } from "react-router-dom";
// import { getTicket } from "../services/getDatosTicket";
// import { verifyToken } from "../services/getUser";

function CustomModal({
  children,
  setOpenModal,
  ticketId,
  title = null,
  onSave = false,
  onCancel = false,
  size = "xl",
  buttons=[]
}) {

  let modalButton = null;
  if(buttons.length > 0){
    modalButton = buttons.map((button)=> ( 
      <button className="btn btn-success" onClick={button.onClick}>
        {button.title}
      </button>
    ))
  }
  return (
    <>
      <div className="container">
        <div className="row">
          <div className="col-12">
            <Modal
              show={true}
              onHide={() => setOpenModal(false)}
              dialogClassName="p-5"
              aria-labelledby="custom-modal"
              centered
              size={size}
            >
              <Modal.Header closeButton>
                {title && (
                  <Modal.Title id="custom-modal">{`${title}`}</Modal.Title>
                )}
              </Modal.Header>
              <Modal.Body className="bg-gradient ">{children}</Modal.Body>
              <Modal.Footer>
                {onSave && (
                  <button className="btn btn-success" onClick={() => onSave()}>
                    Guardar
                  </button>
                )}
                {onCancel && (
                  <button className="btn btn-danger" onClick={() => onCancel()}>
                    Cancelar
                  </button>
                )}
                { modalButton }
              </Modal.Footer>
            </Modal>
          </div>
        </div>
      </div>
    </>
  );
}

export default CustomModal;
