import React, { useEffect, useState } from "react";
import { faTruck } from "@fortawesome/free-solid-svg-icons";
import Icon from "../components/Icon";
import { nvl } from "../utilities";

const BarraInfo = ({ data }) => {
  const [datos, setdatos] = useState({
    nroPlanta: "",
    nombrePlanta: "",
    nombre: "",
    nick: "",
    cuit: "",
  });

  useEffect(() => {
    Promise.all([data]).then(() => {
      setdatos(data);
    });
  });

  const codHeader = datos?.nroPlanta; //datos?.codHeader
  const descHeader = datos?.nombrePlanta; //datos?.descHeader
  const headerDescription =
    codHeader === "" ? `${descHeader}` : `${codHeader} - ${descHeader}`;
  return (
    <>
      <div className="user-bar">
        <div className="left-info d-flex col">
          <div className="icono-planta">
            {datos.nroPlanta ? <Icon iconName={faTruck} /> : null}
          </div>
          {/* INSTALACIÓND TRABAJO O USUARIO PROVEEDOR */}
          <span className="text-planta">{headerDescription}</span>
        </div>
        <div className="right-info">
          {/* INFORMACIÓN DEL USUARIO */}
          <div className="user-info">
            <div className="user-info-text row px-3">
              <span style={{ color: "black" }}>
                {datos.nick}
                {/*  {nvl(datos.nombre, null) !== null ? datos.nombre : datos.nick} */}
              </span>
              <span>
                {nvl(datos.cuit, null) !== null ? `CUIT ${datos.cuit}` : null}{" "}
              </span>
            </div>
            <div className="user-info-img" id="user-info-img">
              <span className="user-info-letter" id="user-info-letter"></span>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default BarraInfo;
